<template>
    <div class="dropdown is-up" :class="{ 'is-active': ativo }">
        <div class="dropdown-trigger">
            <button type="button" :disabled="disabled" class="button is-dark" :class="{ 'is-loading': loading }"
                @click="trigger" id="trigger">
                <span class="icon is-large">
                    <Icon name="mdi:upload" />
                </span>
                <span>Upload</span>
            </button>
        </div>
        <div class="dropdown-menu mb-2" id="dropdown-menu2" role="menu">
            <div class="dropdown-content">

                <div class="dropdown-item">
                    <div class="file">
                        <label class="file-label">
                            <input class="file-input" accept="image/*" type="file" name="resume" @input="upload" />

                            <span class="file-cta">
                                <span class="icon-text is-size-6">
                                    <span class="icon">
                                        <Icon name="mdi:file-image-outline" class="mr-1" color="#64C5E8" size="2rem" />
                                    </span>
                                    <span>Imagem</span>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>

                <div class="dropdown-item">
                    <div class="file">
                        <label class="file-label">
                            <input class="file-input" accept="video/*" type="file" name="resume" @input="upload" />

                            <span class="file-cta">
                                <span class="icon-text is-size-6">
                                    <span class="icon">
                                        <Icon name="mdi:movie" class="mr-1" color="#02A698" size="2rem" />
                                    </span>
                                    <span>Vídeo</span>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>

                <div class="dropdown-item">
                    <div class="file">
                        <label class="file-label">
                            <input class="file-input" accept="audio/ogg" type="file" name="resume" @input="upload" />

                            <span class="file-cta">
                                <span class="icon-text is-size-6">
                                    <span class="icon">
                                        <Icon name="mdi:music" class="mr-1" color="#FFBC38" size="2rem" />
                                    </span>
                                    <span>Áudio</span>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
                <div class="dropdown-item">
                    <div class="file">
                        <label class="file-label">
                            <input class="file-input" accept=".pdf" type="file" name="resume" @input="upload" />

                            <span class="file-cta">
                                <span class="icon-text is-size-6">
                                    <span class="icon">
                                        <Icon name="mdi:file-document" class="mr-1" color="#FF2E74" size="2rem" />
                                    </span>
                                    <span>Documento</span>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['subindo', 'falha', 'sucesso', 'arquivoSelecionado'])
let ativo = ref(false)
const loading = ref(false)
const { $api } = useNuxtApp()
const modalAtivo = ref(false)
const user = useAuthStore()

defineProps({
    disabled: {
        type: Boolean,
        default: false
    }
})

function trigger() {
    ativo.value = !ativo.value
}

async function upload(event) {

    emit('subindo')
    trigger()
    loading.value = true
    const file = event.target.files[0]

    const tamanho = (file.size / 1048576).toFixed(2)

    console.log('tamanho ->', tamanho)
    console.log('user.armazenamento_usado ->', user.armazenamento_usado)

    if (parseFloat(tamanho) + parseFloat(user.armazenamento_usado) >= user.armazenamento_limite) {
        loading.value = false
        event.target.value = ''
        useErrorToast('Limite de MB excedido')
        emit('falha')
        return
    }


    const body = new FormData();
    body.append('file', file, file.name);

    await $api('/api/upload/', { method: 'post', body: body })
        .then(response => {
            if (file.type.startsWith('video/')) {
                const video = document.createElement('video');
                video.src = URL.createObjectURL(file)
                video.style.display = 'none'
                document.body.appendChild(video)

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = 480;
                canvas.height = 480;

                video.addEventListener('loadeddata', function () {
                    video.currentTime = 2;
                });

                video.addEventListener('seeked', function () {
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                    let thumbnail = canvas.toDataURL('image/png').split(',')[1];

                    $api(`/api/arquivos/${response.id}/`, { method: 'patch', body: { thumbnail: thumbnail } }).then(response => {
                        loading.value = false
                        emit('sucesso')
                    })

                });

                video.load();

            } else {
                loading.value = false
                emit('sucesso')
            }

            event.target.value = ''

        })
        .catch(error => {
            loading.value = false

            if (error.status === 400) {
                useErrorToast('Limite de MB excedido')
            }

            else {
                useErrorToast('Falha no upload do Arquivo')
            }

            event.target.value = ''
            emit('falha')
        })

}

</script>

<style lang="scss" scoped>
.dropdown-item p {
    font-size: large;
}

.dropdown-item {
    padding-left: 5px;
    padding-right: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 5px
}

.dropdown-item:hover {
    background-color: #F3F4F6;
}

div .file {
    min-width: 100%;
}

.file-label,
.file-cta {
    min-width: 10rem;
}

.file-cta {
    padding-left: 0 !important;
    background-color: transparent;
    border: none;
    width: 100%;
}
</style>